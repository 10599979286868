import React from 'react';
import { Parallax } from 'react-scroll-parallax';
// components
import { FalkonLogo } from '../svgs';
import ParallaxImage from '../parallax/ParallaxImage';
import SectionTrigger from '../SectionTrigger';

function PromiselandContent({ images }) {
  const [
    promiselandImg01,
    promiselandImg02,
    promiselandImg03,
    promiselandImg04,
    promiselandImg05,
    promiselandImg06,
    promiselandImg07,
    promiselandImg08,
    promiselandImg09,
    promiselandImg10,
    promiselandImg11,
    promiselandImg12,
    promiselandImg13,
    promiselandImg14,
  ] = images;
  // const scrollOffset = [40, -20];
  const scrollOffset = [0, 0];

  return (
    <div>
      <SectionTrigger bgColor="#1381a2" textColor="#fff">
        <div className="pl-hero">
          <div className="pl-hero__container">
            <div className="pl-hero__header">
              <FalkonLogo className="pl-hero__header-logo" />
              <h3 className="main-heading pl-hero__header-hdg">
                PROMISELAND
              </h3>
              <p className="main-copy pl-hero__header-copy">
                PREMIUM DOCUSERIES
              </p>
            </div>
          </div>
          <img className="pl-hero__img" src={promiselandImg01} alt="" />
        </div>
      </SectionTrigger>
      <div className="pl__container">
        <h3 className="main-heading synopsis pl__heading pl__heading--1">
          SYNOPSIS
        </h3>
        <p className="main-copy pl__copy pl__copy--1">
          This series follows the intimate journey and personal evolution of a professional athlete during their rookie season in the NBA, shining a spotlight on a normal kid as his dreams unfold in real-time.
        </p>
        <blockquote className="blockquote pl__blockquote">
          “Ja became an incredible lens to view the changing world.”
        </blockquote>
        <p className="main-copy pl__copy pl__copy--2">
          Through Ja Morant, we will witness a story of the ultimate underdog, coming from nothing, against all odds and everyone’s predictions, only to defy the expected and wind up the #2 pick in the Draft and ultimately achieve Rookie of The Year. His star will illuminate before the viewer’s eyes — exciting the hopes and dreams of every kid with a ball and a dream.
        </p>
        <h3 className="main-heading pl__heading pl__heading--2">
          PARTNERS
        </h3>
        <p className="main-copy pl__copy pl__copy--3">
          DLP MEDIA + WAFFLE IRON ENTERTAINMENT + INTERSCOPE FILMS + SLAM & RTG FEATURES + JA MORANT and a dream.
        </p>
        <h3 className="main-heading conversation pl__heading pl__heading--3">
          CONVERSATION
        </h3>
        <Parallax className="pl__copy pl__copy--4" y={scrollOffset}>
          <p className="main-copy moret">
            “It was incredibly important to find a way to keep cameras rolling through everything…. You only get one chance to capture a moment like this, and Ja became an incredible lens to view the changing world.”
            <br />
            <br />
            - Mike Hughes, founder and CEO of DLP Media Group.
          </p>
        </Parallax>

        <Parallax className="pl__copy pl__copy--5" y={scrollOffset}>
          <p className="main-copy moret">
            “The series will highlight Morant’s rising star from high-school prospect to a must-see NBA talent.”
            <br />
            <br />
            - The Source
          </p>
        </Parallax>
        <Parallax className="pl__copy pl__copy--6" y={scrollOffset}>
          <p className="main-copy moret">
            “The culture of music and sports have always been intertwined, ‘Promiseland’ has been an incredible opportunity for our artists to add tremendous texture to Ja’s story.”
            <br />
            <br />
            — Anthony Seyler, EVP of Interscope Films
          </p>
        </Parallax>
        <Parallax className="pl__copy pl__copy--7" y={scrollOffset}>
          <p className="main-copy moret">
            “There was this itch in the back of my mind around this idea that we as an audience never really got super intimate, visceral, with an athlete on the real precipice of going from a regular kid to a superstar,” Deboree says. “I think people would be really fascinated as I am to know what it’s really like at the point when it’s really happening to you, and you’re experiencing it for the first time.”
            <br />
            <br />
            - Dexton Debore, Creator,
            <span>Show-runner and Director of Promiseland</span>
          </p>
        </Parallax>
        <img
          className="pl__img pl__img--2"
          src={promiselandImg02}
          alt=""
        />
        <img
          className="pl__img pl__img--3"
          src={promiselandImg03}
          alt=""
        />
        <Parallax className="pl__img pl__img--4" y={scrollOffset}>
          <ParallaxImage src={promiselandImg04} alt="" />
        </Parallax>
        <img
          className="pl__img pl__img--5"
          src={promiselandImg05}
          alt=""
        />
        <Parallax className="pl__img pl__img--6" y={scrollOffset}>
          <ParallaxImage src={promiselandImg06} alt="" />
        </Parallax>
        <img
          className="pl__img pl__img--7"
          src={promiselandImg07}
          alt=""
        />
        <img
          className="pl__img pl__img--8"
          src={promiselandImg08}
          alt=""
        />
        <img
          className="pl__img pl__img--9"
          src={promiselandImg09}
          alt=""
        />
        <img
          className="pl__img pl__img--10"
          src={promiselandImg10}
          alt=""
        />
        <img
          className="global-box-shadow pl__img pl__img--11"
          src={promiselandImg11}
          alt=""
        />
        <img
          className="global-box-shadow pl__img pl__img--12"
          src={promiselandImg12}
          alt=""
        />
        <img
          className="global-box-shadow pl__img pl__img--13"
          src={promiselandImg13}
          alt=""
        />
        <img
          className="pl__img pl__img--14"
          src={promiselandImg14}
          alt=""
        />
      </div>
      <SectionTrigger
        bgColor="#1381a2"
        className="pl-section"
        textColor="#fff"
      />
    </div>
  )
}

export default PromiselandContent;